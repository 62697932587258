import React from 'react'
import SectionHeader from "../SectionHeader"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import AboutInfo from "../AboutInfo"

function AboutMe(props) {
    return (
        <section id={"about"} className="section-padding alt-background">
            <Container>
                <SectionHeader
                    subTitle={"About"}
                    title={"About Me"}
                    description={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum standard dummy text."}
                />
                <Row>
                    <Col lg="5" className="text-center wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img className="fahad-image" src={require('../../assets/images/me-01-alt.png')}/>
                    </Col>
                    <Col lg="7" className={"my-auto"}>
                        <h3 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                            Hi There
                        </h3>
                        <div className="about-me-description wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                            <p>I'm a full stack developer with 2+ years of experience in developing and managing web/mobile platforms.</p>
                            <p>Launching a new web platform or mobile application is a complex process. My aim is to design and deliver your solution in a simple, cost effective manner with a key focus on customer experience & profitability.</p>
                        </div>
                        <Row>
                            <AboutInfo
                                className="wow fadeInLeft" dataDuration="1s" dataDelay="0.5s"
                                header={"Name:"}
                                info={"Mohammed Fahad"}
                            />
                            <AboutInfo
                                className="wow fadeInRight" dataDuration="1s" dataDelay="0.6s"
                                header={"Email:"}
                                info={"fmohajir@gmail.com"}
                            />
                            <AboutInfo
                                className="wow fadeInLeft" dataDuration="1s" dataDelay="0.7s"
                                header={"Phone:"}
                                info={"+97150-849-1339"}
                            />
                            <AboutInfo
                                className="wow fadeInRight" dataDuration="1s" dataDelay="0.8s"
                                header={"Linkedin:"}
                                info={<a target="_blank" href="https://www.linkedin.com/in/mohammed-fahad-29a988165/">mfahad.58</a>}
                            />
                            <AboutInfo
                                className="wow fadeInLeft" dataDuration="1s" dataDelay="0.9s"
                                header={"CV"}
                                info={<a target="_blank" href="https://1drv.ms/b/s!AgVGby8rayTEiP0MMyOsTzQLn7I9VQ">View Here</a>}
                            />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutMe