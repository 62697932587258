import React from 'react'
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component/dist-es6"
import 'react-vertical-timeline-component/style.min.css'
import SectionHeader from "../SectionHeader"
import './styles.scss'

function Experience(props) {
    return (
        <section id={"experience"} className="section-padding alt-background">
            <Container>
                <SectionHeader
                    subTitle={"Experience"}
                    title={"My Experience"}
                />
                <Row className="justify-content-md-center">
                    <Col sm lg="11">
                        <VerticalTimeline>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work dark"
                                date="Feb 2018 - present"
                            >
                                <h4 className="vertical-timeline-element-title">Web Developer</h4>
                                <h6 className="vertical-timeline-element-subtitle">QNTM.tech</h6>
                                <p>Built Multiple Websites for Clients</p>
                                <p>Improved Codes to Automate Tasks</p>
                                <p>Maintained the Company Website</p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work dark"
                                date="June 2018 - December 2019"
                            >
                                <h4 className="vertical-timeline-element-title">Lead Developer</h4>
                                <h6 className="vertical-timeline-element-subtitle">Work Simplr</h6>
                                <p>MVP Design & Development</p>
                                <p>Ease in Investor Acquisition</p>
                                <p>Initiation of SCRUM & Agile Process</p>
                                <p>Improved Profitability Potential</p>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Experience