import React from 'react'
import Form from "react-bootstrap/esm/Form"
import Col from "react-bootstrap/esm/Col"
import Button from "react-bootstrap/esm/Button"
import './styles.scss'
import Alert from "react-bootstrap/esm/Alert"
import axios from 'axios'

class ContactForm extends React.Component {


    state = {
        alert: {
            show: false,
            variant: "success",
            message: "Success",
            className: "hidden"
        },
        formValues: {
            name: "", subject: "", email: "", message: ""
        }
    }

    handleAlertClose = () => {
        this.setState({
            alert: {
                ...this.state.alert,
                show: false,
                className: "hidden"
            }
        })
    }

    handleForm = e => {
        axios.post(
            "https://formcarry.com/s/IIAv3GJZDqc_",
            this.state.formValues,
            {headers: {"Accept": "application/json"}}
        )
            .then((response) => {
                if (response.data.code === 200) {
                    // handle success
                    this.setState({
                        alert: {
                            show: true,
                            variant: "success",
                            message: "Your message has been successfully delivered, we will get back to you as soon as possible!",
                            className: "visible"
                        }
                    })
                } else {
                    this.setState({
                        alert: {
                            show: true,
                            variant: "danger",
                            message: "Your message could not be delivered, please try again later!",
                            className: "visible"
                        }
                    })
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    alert: {
                        show: true,
                        variant: "danger",
                        message: "Your message could not be delivered, please try again later!",
                        className: "visible"
                    }
                })
            });

        e.preventDefault();
    }


    handleFields = e => this.setState({
        formValues: {
            ...this.state.formValues,
            [e.target.name]: e.target.value
        }
    });

    render() {

        return (
            <div>
                <Form className="contact-form-holder wow fadeInUp" dataDuration="1.2s" data-wow-delay="0.5s"
                      onSubmit={this.handleForm}>
                    <Form.Row className="contact-form-row">
                        <Col xs={12} sm={6} className="contact-form-column">
                            <Form.Control placeholder="Your Name" name="name" required onChange={this.handleFields}/>
                        </Col>
                        <Col xs={12} sm={6} className="contact-form-column">
                            <Form.Control placeholder="Your Email" type="email" name="email" required
                                          onChange={this.handleFields}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="contact-form-row">
                        <Col>
                            <Form.Control placeholder="Subject" name="subject" required onChange={this.handleFields}/>
                        </Col>
                    </Form.Row>
                    <Form.Row className="contact-form-row">
                        <Col>
                            <Form.Control as="textarea" rows="3" placeholder="Your Message" name="message" required
                                          onChange={this.handleFields}/>
                        </Col>
                    </Form.Row>
                    <Button variant="primary" type="submit">
                        {'Submit'}
                    </Button>
                </Form>

                <Alert className={"alert-class " + this.state.alert.className} variant={this.state.alert.variant}
                       onClose={this.handleAlertClose} dismissible>
                    <Alert.Heading>{this.state.alert.message}</Alert.Heading>
                </Alert>
            </div>
        )
    }


}

export default ContactForm