import React from 'react'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import './styles.scss'
function SectionHeader(props){
    return (
        <div className="container">
            <Row className="justify-content-md-center">
                <Col lg="6">
                    <div className="section-header-title underline">
                        <span>{props.subTitle}</span>
                        <h2>
                            {props.title}
                        </h2>
                        <p>{props.description}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SectionHeader