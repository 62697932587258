import React from 'react'
import SectionHeader from "../SectionHeader"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import ServiceCard from "../ServiceCard"
import {ReactComponent as WebDesign} from '../../assets/icons/web-design.svg'
import {ReactComponent as WebDevelopment} from '../../assets/icons/web-development.svg'
import {ReactComponent as MobileApps} from '../../assets/icons/mobile-app.svg'
import {ReactComponent as BackendArch} from '../../assets/icons/backend-arch.svg'
import {ReactComponent as AutomatedBots} from '../../assets/icons/bots.svg'
import {ReactComponent as Serverless} from '../../assets/icons/serverless.svg'

function Services(props) {
    return (
        <section id={"services"} className="section-padding">
            <SectionHeader
                subTitle={"Get things done"}
                title={"My Services"}
            />
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={4} md={6} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                        <ServiceCard
                            icon={<WebDesign className="bigger-icon"/>}
                            title={"Web Platform Design"}
                            description={"Clean and minimalistic UI/UX design for web and mobile apps"}
                        />
                    </Col>
                    <Col lg={4} md={6} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.6s">
                        <ServiceCard
                            icon={<WebDevelopment className="bigger-icon"/>}
                            title={"Web Development"}
                            description={"Fast, intuitive and clean web apps that work across all screen-sizes"}
                        />
                    </Col>
                    <Col lg={4} md={6} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.7s">
                        <ServiceCard
                            icon={<MobileApps className="bigger-icon"/>}
                            title={"Mobile Apps"}
                            description={"Native mobile apps that cater to IOS and Android users"}
                        />
                    </Col>
                    <Col lg={4} md={6} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.8s">
                        <ServiceCard
                            icon={<BackendArch className="bigger-icon"/>}
                            title={"Backend Architecture"}
                            description={"Build a fast and scalable backend with modern day best practices"}
                        />
                    </Col>
                    <Col lg={4} md={6} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.9s">
                        <ServiceCard
                            icon={<AutomatedBots className="bigger-icon"/>}
                            title={"Automated Bots"}
                            description={"Offload and Automate repetitive tasks with multiple Bots"}
                        />
                    </Col>
                    <Col lg={4} md={6} className="wow fadeInDown" data-wow-duration="1s" data-wow-delay="1s">
                        <ServiceCard
                            icon={<Serverless className="bigger-icon"/>}
                            title={"Serverless REST API's"}
                            description={"Develop and Deploy AWS Lambda Functions with Serverless"}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Services