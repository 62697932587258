import React from 'react'
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import './styles.scss'
import Button from "react-bootstrap/esm/Button"
import {IconContext} from "react-icons";
import {FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa';

const redirectPage = (url) =>{
    window.open(url, "_blank")
}

function Footer(props) {
    return (
        <section className="section-padding alt-background">
            <Container>
                <Row>
                    <Col lg={4} className="footer-links">
                        <h3>Sitemap</h3>
                        <ul>
                            <li className="wow fadeInLeft"
                                data-wow-duration="1s"
                                data-wow-delay="1s"
                            ><a href={"#home"}>Home</a></li>
                            <li className="wow fadeInLeft"
                                data-wow-duration="1s"
                                data-wow-delay="0.8s"
                            ><a href={"#services"}>Services</a></li>
                            <li className="wow fadeInLeft"
                                data-wow-duration="1s"
                                data-wow-delay="0.6s"
                            ><a href={"#experience"}>Experience</a></li>
                            <li className="wow fadeInLeft"
                                data-wow-duration="1s"
                                data-wow-delay="0.2s"
                            ><a href={"#portfolio"}>Portfolio</a></li>
                            <li className="wow fadeInLeft"
                                data-wow-duration="1s"
                                data-wow-delay="0s"
                            ><a href={"#contact"}>Contact</a></li>
                        </ul>
                    </Col>

                    <Col lg={4} className="footer-icons">
                        <h3>Social</h3>
                        <IconContext.Provider value={{className: "global-class-name"}}>
                            <ul>
                                <li>
                                    <Button
                                        onClick={()=>redirectPage("https://www.facebook.com/mohd.fahad.58")}
                                        className="wow fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="1s"
                                        variant="primary eq-padding">
                                        <FaFacebookF/>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        onClick={()=>redirectPage("https://www.twitter.com/ydm619")}
                                        className="wow fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="0.9s"
                                        variant="primary eq-padding">
                                        <FaTwitter/>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        onClick={()=>redirectPage("https://www.instagram.com/fayhaed/")}
                                        className="wow fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="0.8s"
                                        variant="primary eq-padding">
                                        <FaInstagram/>
                                    </Button>
                                </li>
                                <li>
                                    <Button
                                        onClick={()=>redirectPage("https://www.linkedin.com/in/mohammed-fahad-29a988165/")}
                                        className="wow fadeInUp"
                                        data-wow-duration="1s"
                                        data-wow-delay="0.7s"
                                        variant="primary eq-padding">
                                        <FaLinkedinIn/>
                                    </Button>
                                </li>
                            </ul>
                        </IconContext.Provider>
                    </Col>

                    <Col lg={4} className="footer-brand">
                        <h3>M. F A H A D</h3>
                        <p>© 2020</p>

                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Footer