import React from 'react'
import Card from "react-bootstrap/esm/Card"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import './styles.scss'
import {IconContext} from "react-icons";

function ContactCard(props) {
    return (
        <Card className="contact-card" onClick={props.onClick}>
            <Card.Body>
                <Row>
                    <Col lg={2} sm={2} xs={2} className="contact-icon-holder">
                        <IconContext.Provider
                            value={{className: "contact-icon"}}>
                            {props.icon}
                        </IconContext.Provider>
                    </Col>
                    <Col lg={10} sm={10} xs={10} >
                        <Card.Title>{props.title}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{props.description}</Card.Subtitle>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ContactCard