import React from 'react'
import Card from "react-bootstrap/Card"
import {IconContext} from "react-icons";
import {FaGithub, FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa';
import './styles.scss'
import {ReactComponent as WebDesign} from '../../assets/icons/web-design.svg'

function ServiceCard(props) {
    return (
        <Card className={"service-card"}>
            <Card.Body>
                <Card.Title>
                    {props.icon}
                </Card.Title>
                <Card.Subtitle>{props.title}</Card.Subtitle>
                <Card.Text>
                    {props.description}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ServiceCard