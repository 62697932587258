import React, {Component} from 'react';
import './App.css';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import NavBar from './containers/NavBar'
import Introduction from "./components/Introduction"
import Services from "./components/Services"
import AboutMe from "./components/AboutMe"
import Experience from "./components/Experience"
import Education from "./components/Education"
import Portfolio from "./components/Portfolio"
import Technologies from "./components/Technologies"
import Contact from "./components/Contact"
import WOW from 'wow.js'
import 'wow.js/css/libs/animate.css'
import {IconContext} from "react-icons";
import {FaRocket} from 'react-icons/fa'
import Footer from "./components/Footer"

class App extends Component {

    state={
        goToTopVisible: "hide"
    }

    componentDidMount() {
        const wow = new WOW()
        wow.init()
        window.addEventListener("scroll", this.myScrollFunc)
    }

    myScrollFunc = () =>{
        let y = window.scrollY;
        if (y >= 500) {
            this.setState({
                goToTopVisible: "show"
            })
        } else {
            this.setState({
                goToTopVisible: "hide"
            })
        }
    }

    scrollToTop = () =>{
        window.scrollTo({
            behavior: 'smooth',
            top: 0
        })
    }

    render() {
        return (
            <div>
                <NavBar />
                <Introduction />
                <Services />
                <Experience />
                <Portfolio />
                <Technologies/>
                <Contact />
                <Footer/>
                <div className={"go-to-top " + this.state.goToTopVisible} onClick={this.scrollToTop}>
                    <IconContext.Provider
                        value={{className: "go-to-top-icon"}}>
                        <FaRocket/>
                    </IconContext.Provider>
                </div>
                <Container>
                    <Row className="justify-content-md-center">

                        <Col sm lg="12">
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default App;
