import React from 'react'
import Card from "react-bootstrap/Card"
import './styles.scss'
import {FaArrowAltCircleRight} from 'react-icons/fa'

function PortfolioCard(props) {
    return (
        <Card className={"portfolio-card"} onClick={props.onClick}>
            <Card.Img
                resizeMode={'cover'}
                variant="top" src={props.imageSrc}/>
            <Card.Body>
                <Card.Title>
                    {props.cardTitle}
                </Card.Title>
                <Card.Subtitle>
                    {props.cardSubtitle}
                </Card.Subtitle>
                <Card.Text>
                    {props.cardDescription}
                </Card.Text>{/*
                <Card.Link href="#">
                    <div className={"portfolio-link"}>
                        Read More <FaArrowAltCircleRight className={"portfolio-link-icon-holder"}/>
                    </div>
                </Card.Link>*/}
            </Card.Body>
        </Card>
    )
}

export default PortfolioCard