import React from 'react'
import SectionHeader from "../SectionHeader"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import PortfolioCard from "../PortfolioCard"

function Portfolio() {
    return (
        <section id={"portfolio"} className="section-padding">
            <SectionHeader
                subTitle={"Past Projects"}
                title={"My Portfolio"}
            />
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={6} md={6} className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <PortfolioCard
                            onClick={()=>window.open("https://www.worksimplr.com")}
                            imageSrc={require('../../assets/images/worksimplr.png')}
                            cardTitle={"Work Simplr"}
                            cardSubtitle={"MERN Stack"}
                            cardDescription={"WorkSimplr is a start-up based on the UK focused at monetizing the immense potential of the Gig-Economy."}
                        />
                    </Col>
                    <Col lg={6} md={6} className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <PortfolioCard
                            onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.mfahad.simple_calculator")}
                            imageSrc={require('../../assets/images/simplecal.png')}
                            cardTitle={"Simple Calculator"}
                            cardSubtitle={"React Native"}
                            cardDescription={"A simple calculator based on react native which includes unit and crypto-currency conversions"}
                        />
                    </Col>
                    <Col lg={6} md={6} className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <PortfolioCard
                            onClick={()=>window.open("http://www.nshashank.com")}
                            imageSrc={require('../../assets/images/nshashank.png')}
                            cardTitle={"N.Shashank"}
                            cardSubtitle={"HTML/CSS"}
                            cardDescription={"Nshanshank.com was a personal website developed for Shashank, an experienced Digital Transformation adviser and Technology Best Practice advocate."}
                        />
                    </Col>
                    <Col lg={6} md={6} className="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                        <PortfolioCard
                            onClick={()=>window.open("http://sholo-bot-frontent.s3-website.eu-west-2.amazonaws.com/")}
                            imageSrc={require('../../assets/images/sholo.png')}
                            cardTitle={"ShoLo Bot"}
                            cardSubtitle={"MERN Stack"}
                            cardDescription={"ShoLo is a Futures Trading strategy where a volatility-trader enters a position and go for both Short and Long simultaneously from two different BitMEX accounts."}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Portfolio