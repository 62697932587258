import React from 'react'
import Jumbotron from "react-bootstrap/Jumbotron"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'
import './styles.scss'
import {IconContext} from "react-icons";
import {FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa';

const redirectPage = (url) =>{
    window.open(url, "_blank")
}

function Introduction() {
    return (
        <section id={"home"} className="banner section-padding alt-background wow fadeIn" data-wow-offset="50"
                 data-wow-delay="0.5s">
            <Container>
                <Row className="justify-content-md-center reverse">
                    <Col lg="7">
                        <Jumbotron className={"jumbo"}>
                            <IconContext.Provider value={{className: "global-class-name"}}>
                                <ul>
                                    <li>
                                        <Button
                                            onClick={()=>redirectPage("https://www.facebook.com/mohd.fahad.58")}
                                            className="wow slideInLeft"
                                            data-wow-duration="2s"
                                            data-wow-delay="1s"
                                            variant="primary eq-padding">
                                            <FaFacebookF/>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            onClick={()=>redirectPage("https://www.twitter.com/ydm619")}
                                            className="wow slideInLeft"
                                            data-wow-duration="2s"
                                            data-wow-delay="0.9s"
                                            variant="primary eq-padding">
                                            <FaTwitter/>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            onClick={()=>redirectPage("https://www.instagram.com/fayhaed/")}
                                            className="wow slideInLeft"
                                            data-wow-duration="2s"
                                            data-wow-delay="0.8s"
                                            variant="primary eq-padding">
                                            <FaInstagram/>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button
                                            onClick={()=>redirectPage("https://www.linkedin.com/in/mohammed-fahad-29a988165/")}
                                            className="wow slideInLeft"
                                            data-wow-duration="2s"
                                            data-wow-delay="0.7s"
                                            variant="primary eq-padding">
                                            <FaLinkedinIn/>
                                        </Button>
                                    </li>
                                </ul>
                            </IconContext.Provider>
                            <Typist className="intro-name" startDelay={1000} cursor={{
                                show: true,
                                blink: true,
                                element: '|',
                                hideWhenDone: true,
                                hideWhenDoneDelay: 2000,
                            }}>
                                I am Mohammed Fahad
                            </Typist>
                            <p className={"description"}>
                                Launching a new web platform or mobile application is a complex process. My aim is to design and deliver your solution in a simple, cost effective manner with a key focus on customer experience & profitability.
                            </p>
                            <ul>
                                <li>
                                    <Button className="mb-2 mt-2" href={'#portfolio'} variant="primary">My Portfolio</Button>
                                </li>
                                <li>
                                    <Button className="mb-2 mt-2" onClick={()=>redirectPage("https://1drv.ms/b/s!AgVGby8rayTEiP1EIzmk88nISv-UVQ?e=rnvbQr")} variant="primary">View CV</Button>
                                </li>
                            </ul>
                        </Jumbotron>
                    </Col>
                    <Col lg="5">
                        <div className="fahad-image-holder wow fadeInRight" data-wow-duration="1s"
                             data-wow-delay="0.5s">
                            <img className="fahad-image" src={require('../../assets/images/fahad_image.png')}/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}

export default Introduction