import React from 'react'
import SectionHeader from "../SectionHeader"
import Col from "react-bootstrap/esm/Col"
import Row from "react-bootstrap/esm/Row"
import Container from "react-bootstrap/esm/Container"
import ContactCard from "../ContactCard"
import {FaLocationArrow, FaPhone, FaMailBulk} from 'react-icons/fa'
import ContactForm from "../ContactForm"

function Contact() {
    return (
        <section id={"contact"} className="section-padding">
            <SectionHeader
                subTitle={"Have a query?"}
                title={"Get In Touch"}
            />
            <Container>
                <Row>
                    <Col lg={4} className="wow fadeInLeft" dataDuration="1s" data-wow-delay="0.7s">
                        <ContactCard
                            onClick={()=>window.open("https://www.google.com/maps/place/Dubai/@25.0752826,55.0876631,11z/data=!3m1!4b1!4m5!3m4!1s0x3e5f43496ad9c645:0xbde66e5084295162!8m2!3d25.2048493!4d55.2707828")}
                            icon={<FaLocationArrow/>}
                            title={"Locate me"}
                            description={"Dubai, UAE"}
                        />
                    </Col>
                    <Col lg={4} className="wow fadeIn" dataDuration="1s" data-wow-delay="0.5s">
                        <ContactCard
                            onClick={()=>{
                                window.open("tel:00971508491339")
                            }}
                            icon={<FaPhone/>}
                            title={"Give me a call"}
                            description={"+97150-849-1339"}
                        />
                    </Col>
                    <Col lg={4} className="wow fadeInRight" dataDuration="1s" data-wow-delay="0.7s">
                        <ContactCard
                            onClick={()=>window.open("mailto:hello@fahadhere.com")}
                            icon={<FaMailBulk/>}
                            title={"Send an email"}
                            description={"hello@fahadhere.com"}
                        />
                    </Col>
                </Row>
                <ContactForm/>
            </Container>
        </section>
    )
}

export default Contact